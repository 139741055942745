<template>
 <div>
     <div class="ntMain__cont flex1 flexbox flex-col">
    <div class="nt__lgregWrapper flex1 flexbox flex-col">
        <div class="nt__lgregHeader flex1">
           <div class="slogan"><div class="logo"><img src="@assets/v3-logo.png" /></div> <h2>Vue3-WebChat桌面端聊天室</h2>
          </div>
          <div class="forms">
          <form @submit.prevent="handleSubmit">
          <ul class="clearfix">
           <li class="flexbox flex-alignc"><input class="iptxt flex1" type="text" v-model="formObj.tel" placeholder="请输入手机号" autocomplete="off" maxLength="11" /><em class="borLine"></em></li>
             <li class="flexbox flex-alignc"><input class="iptxt flex1" type="password" v-model="formObj.pwd" placeholder="请输入密码" autocomplete="off" /><em class="borLine"></em></li>
            <li class="flexbox flex-alignc"><input class="iptxt flex1" type="text" v-model="formObj.vcode" placeholder="验证码" autocomplete="off" /><em class="borLine"></em><button class="btn-getcode" @click.prevent="handleVcode" :disabled="disabled">{{vcodeText}}</button></li>
             </ul>
            <div class="btns"><button class="vui__btn vui__btn-primary btn__login" type="submit">注册</button></div>
            <div class="lgregLink align-c clearfix">
           <router-link class="navigator" to="/login">已有账号，去登录</router-link>
            </div>
         </form>
        </div>
         </div>
        <div class="nt__lgregFooter"><p class="version">私域宝.聚合聊天 v1.0.0</p>
          </div>
         </div>
     </div>
 </div>
</template>
<script>
import {reactive, toRefs,inject,getCurrentInstance} from 'vue'
export default {
    components: {},
    setup() {
     const { ctx } = getCurrentInstance()
        const v3layer = inject('v3layer')
     const utils = inject('utils')
    const formObj = reactive({})
     const data = reactive({vcodeText: '获取验证码',disabled: false, time: 0,
        })
        const VTips = (content) => {
         v3layer({content: content, layerStyle: 'background:#ff5151;color:#fff;', time: 2
        })
        }
        const handleSubmit = () => {
            if(!formObj.tel){VTips('手机号不能为空！')
            }else if(!utils.checkTel(formObj.tel)){ VTips('手机号格式不正确！')
            }else if(!formObj.pwd){VTips('密码不能为空！')
            }else if(!formObj.vcode){ VTips('验证码不能为空！')
            }else{
            ctx.$store.commit('user/SET_TOKEN', utils.setToken());
             ctx.$store.commit('user/SET_USER', formObj.tel);
                v3layer({ content: '恭喜，注册成功！', layerStyle: 'background:#3dbb51;color:#fff;', time: 2, shadeClose: false,
                  onEnd() { ctx.$router.push('/')
                 }
              })
          }
      }
       const handleVcode = () => {
         if(!formObj.tel) { VTips('手机号不能为空！')
            }else if(!utils.checkTel(formObj.tel)) { VTips('手机号格式不正确！')
            }else { data.time = 60; data.disabled = true; countDown();
          }
        }
        const countDown = () => {
        if(data.time > 0) {
            data.vcodeText = '获取验证码('+ data.time +')'
             data.time--
            setTimeout(countDown, 1000)
         }else{
            data.vcodeText = '获取验证码'
             data.time = 0
          data.disabled = false
         }
      }
     return {formObj, ...toRefs(data), handleSubmit, handleVcode
     }
  }
}
</script>
